import React from 'react'
import PropTypes from 'prop-types'

import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Intro</h2>
          <span className="image main"><img src={pic01} alt="" /></span>
          <p>Quantum Chalk, Inc. produces a range of scientific and educational software. 
            We believe that current state of both scientific research and education at all levels has the potential for massive improvement, but that for this
            to be realized
            a new generation of tools is required. 
            <br/><br/>It is our mission to build those tools.
          </p>
          {close}
        </article>

        <article id="technology" className={`${this.props.article === 'technology' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Technology</h2>
          <span className="image main"><img src={pic02} alt="" /></span>
          <p>Our applications are powered by our own full stack technologies. These include reactive front-end web interfaces that synchronize to our OwlCloud servers.
             Our LambdaSwarm system is used to coordinate parallel serverless (lambda) functions for maximum robustness and scalability, allowing efficient cooperation
             with our full range of machine learning systems.
             </p>
          {close}
        </article>

        <article id="products" className={`${this.props.article === 'products' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Products</h2>
          <span className="image main"><img src={pic02} alt="" /></span>
          <p>Our main products are currently under development, but you can see examples of our applications at allquakes.org.
             </p>
          {close}
        </article>

        <article id="solutions" className={`${this.props.article === 'solutions' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Custom Solutions</h2>
          <span className="image main"><img src={pic02} alt="" /></span>
          <p>Because our systems have been custom-built to work seamlessly together, they form very efficient and flexible building blocks for a wide range of 
             applications. Whatever your data, computation, and visualization requirements, it's likely that our systems can be tailored to provide a robust
             solution far faster than you'd believe possible.
             <br/>If you need a solution, drop us a line.
             </p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <p>For more information please reach us at<br/>
            info@quantumchalk.com
          </p>
{/*
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Send Message" className="special" /></li>
              <li><input type="reset" value="Reset" /></li>
            </ul>
          </form>
          <ul className="icons">
            <li><a href="#" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
            <li><a href="#" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
            <li><a href="#" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
            <li><a href="#" className="icon fa-github"><span className="label">GitHub</span></a></li>
          </ul>
*/}
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main